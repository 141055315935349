// This is not a redux reducer, is used to manage the search page state instead of multiple useState
function searchPageStateReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_PAGE_STATE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default searchPageStateReducer;
