import './styles.scss';
import React, {
  useEffect, useMemo, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import algoliasearch from 'algoliasearch/lite';
import algoliasearchHelper from 'algoliasearch-helper';
import track from 'react-tracking';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Container } from '@edx/paragon';
import classNames from 'classnames';
import searchPageStateReducer from '@data/reducers/searchLocal';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { getUserLocation, getUserCoordinates, getPrecisionRadius } from '@utils/location';
import { intlShape } from '@prospectus/common/types/intlShape';
import SEOPage from '@prospectus/seo/ui/SEOPage';
import { marketingPromotionPropTypes } from '@prospectus/common/ui/MarketingTakeover/propTypes';
import * as searchActions from '@data/actions/search';
import { updateFilters, clearAllFilters } from '@data/actions/filters';
import useGeotargeting from '@prospectus/common/hooks/useGeotargeting';
import useAlgoliaProductIndex from '@prospectus/common/hooks/useAlgoliaProductIndex';
import useOptimizelyIsInitialized from '@prospectus/common/hooks/useOptimizelyIsInitialized';

import {
  parseRawSearchResults,
  programFields,
  courseFields,
  parseFacetsFromResults,
} from '@utils/searchResults';
import useAutocomplete from '@prospectus/common/hooks/useAutocomplete';
import { SearchPageContext, PaidLandingPageContext } from '@utils/context';
import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';

import {
  CARDS_PER_PAGE,
  ALL_TAB_PROGRAM_CARDS_LENGTH,
  getPageTypeFromUrl,
  getResultTypeFromUrl,
  getCurrentBreadcrumbType,
  RESULT_TYPE_COURSE,
  RESULT_TYPE_PROGRAM,
  RESULT_TYPE_EXECUTIVE_EDUCATION,
  RESULT_TYPE_DEGREE_PROGRAM,
  RESULT_TYPE_BOOT_CAMP,
  PRODUCT_TYPES as LEARNING_OPTION_PRODUCT_TYPES,
  PRE_QUERY,
  ALL_AVAILABLE_FACETS,
  FACETS_FOR_LEARNING_TYPE_EXPERIMENT,
  parseVerticalFiltersFacets,
} from '@utils/search';
import useSearchParams from '@prospectus/common/hooks/useSearchParams';
import useExperimentVariables from '@prospectus/common/hooks/useExperimentVariables';
import UserZoomSurvey from '@prospectus/common/ui/UserZoomSurvey';
import { PRODUCT_TYPES } from '@utils/algoliaConstants';
import withUser from '@utils/withUser';
import { enterpriseUser } from '@data/actions/enterprise';
import ContentfulModule from '@prospectus/modular-page/modules/ContentfulModule';
import ContentfulModulesPropType from '@prospectus/modular-page/modules/ContentfulModulesPropType';
import PreQueryPage from '../PreQueryPage';
import SearchResults from '../Results/SearchResults';
import SearchBreadcrumb from '../SearchBreadcrumb';
import messages from '../messages';
import filterMessages from '../RefinementFilters/messages';
import SearchHeader from '../HeaderSection';
import SearchResultsSkeleton from '../Results/SearchResultsSkeleton';
import VerticalFilters from '../RefinementFilters/components/VerticalFilters';

// TODO: [WS-4596] add combined search results experiment back once it is created in the new Optimizely
// import {
//   combinedSearchResultsExperimentOn,
// } from '@utils/optimizelyExperiments';

const SEARCH_RESULTS_SURVEY = {
  id: 'DF508EB25C09EE11AA8A867CE3F1058A',
  sid: '1FDBB24C6B05EE11AA89CC40E66C9C57',
};

const textModuleVersion = 'v1';

function SearchPage(props) {
  const {
    location: {
      pathname,
    },
    pageContext,
    pageContext: {
      defaultPrograms,
      defaultCourses,
      webview,
      initialRefinementCounts,
      initialTotalCourses,
      initialTotalPrograms,
      promotedPrograms,
      promotedCourses,
      courseLocationRestrictionMap,
      programLocationRestrictionMap,
      marketingPromotions,
      modules,
    },
    intl,
    actions,
    userEnrollments,
    requestIsEnterpriseUser,
  } = props;

  const isMobile = useBreakpointMatch('medium');

  // helpers for managing state
  const setResultsToDefault = () => {
    // Because the 2u result types don't require
    // defaults, the initial state is hardcoded in the reducer
    // and gets merged in as defaults when this resetSearch()
    // action is fired.
    actions.resetSearch({
      facets: initialRefinementCounts,
      results: {
        program: {
          items: defaultPrograms,
          total: initialTotalPrograms,
          pages: 0,
        },
        course: {
          items: defaultCourses,
          total: initialTotalCourses,
          pages: 0,
        },
      },
    });
  };

  const {
    searchParams, getQuery, getRefinements, updateSearchParams, cleanupSearchParams, clearRefinements,
  } = useSearchParams();

  const runtimeRenderable = typeof window !== 'undefined'; // no need for this to be stateful

  // initialize state. could use context here but not certain it would save us all that much
  const indexName = useAlgoliaProductIndex(intl);
  const autocompleteState = useAutocomplete(getQuery());
  const {
    setQuery,
    resetResults: resetAutocompleteResults,
  } = autocompleteState;

  // Set up the initial state for useReducer directly using the methods from useSearchParams
  const initialState = {
    lastSearchedQuery: getQuery(),
    activePageType: getPageTypeFromUrl(searchParams),
    activeResultType: getResultTypeFromUrl(searchParams),
    refinements: getRefinements(),
  };

  const [pageState, dispatch] = useReducer(searchPageStateReducer, initialState);
  const { geotargetingEnabled } = useGeotargeting();
  const { showUserZoomSurvey } = !webview && useExperimentVariables({ showUserZoomSurvey: Boolean(typeof window !== 'undefined' && window.localStorage?.getItem('show-uz-survey')) });
  // WS-4468
  const { learningTypeExperimentIsActive } = useExperimentVariables({ learningTypeExperimentIsActive: false });

  const userCountry = getUserLocation();
  const { isLoading: optimizelyIsLoading } = useOptimizelyIsInitialized();
  const showVerticalFilters = !isMobile && pageState.activePageType !== PRE_QUERY;

  const breadCrumbParams = getCurrentBreadcrumbType(searchParams);

  const managePageState = (newPageState) => {
    if (newPageState.query) {
      setQuery(newPageState.query);
    }
    dispatch({
      type: 'UPDATE_PAGE_STATE',
      payload: {
        lastSearchedQuery: newPageState.query,
        activePageType: newPageState.pageType,
        activeResultType: newPageState.resultType,
        refinements: newPageState.refinements,
      },
    });
  };

  // initialize Algolia workers
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  );

  // Specifically list the product values that will be fetched.
  // By listing the values specifically, we can exclude any new
  // values that we don't want to show up without doing more work.
  const productTypesToDisplay = [
    PRODUCT_TYPES.COURSE,
    PRODUCT_TYPES.PROGRAM,
    PRODUCT_TYPES.EXECUTIVE_EDUCATION,
    PRODUCT_TYPES.BOOT_CAMP,
    PRODUCT_TYPES.TWOU_DEGREE, // Should we add here two_degree?
  ];

  const getFilters = () => {
    const productTypeFilter = productTypesToDisplay.map(type => `product:"${type}"`).join(' OR ');

    if (geotargetingEnabled && userCountry) {
      const locationRestrictionFilter = (
        `NOT blocked_in:"${userCountry}" AND (allowed_in:"null" OR allowed_in:"${userCountry}")`
      );
      return `(${productTypeFilter}) AND ${locationRestrictionFilter}`;
    }

    return productTypeFilter;
  };

  const algoliaSearchParams = {
    disjunctiveFacets: learningTypeExperimentIsActive ? FACETS_FOR_LEARNING_TYPE_EXPERIMENT : ALL_AVAILABLE_FACETS,
    facets: ['product'],
    clickAnalytics: true,
    filters: getFilters(),
  };

  if (getUserCoordinates()) {
    algoliaSearchParams.aroundLatLng = getUserCoordinates();
    algoliaSearchParams.aroundRadius = 'all';
    algoliaSearchParams.aroundPrecision = [
      { from: 0, value: getPrecisionRadius() },
    ];
  }

  const searchHelper = algoliasearchHelper(
    searchClient,
    indexName,
    algoliaSearchParams,
  );

  // the derived helpers are only used when Learning Type = Any, since that is when we show
  // four different sets of results on the same page (executive education courses, bootcamps, edX courses, and programs)
  const execEdSearcher = searchHelper.derive((state) => state.addFacetRefinement('product', PRODUCT_TYPES.EXECUTIVE_EDUCATION));
  const bootcampSearcher = searchHelper.derive((state) => state.addFacetRefinement('product', PRODUCT_TYPES.BOOT_CAMP));
  const courseSearcher = searchHelper.derive((state) => (state.addFacetRefinement('product', PRODUCT_TYPES.COURSE)));
  const twoUDegreeSearcher = searchHelper.derive((state) => (state.addFacetRefinement('product', PRODUCT_TYPES.TWOU_DEGREE)));

  const verticalFilters = useSelector((state) => state.filters);

  const applyFilters = () => {
    const currentRefinements = getRefinements(learningTypeExperimentIsActive);
    const newRefinements = { ...currentRefinements };

    Object.keys(verticalFilters).forEach((key) => {
      Object.keys(verticalFilters[key].options).forEach((option) => {
        if (verticalFilters[key].options[option]?.selected) {
          newRefinements[verticalFilters[key].category] = newRefinements[verticalFilters[key].category]
            ? [...new Set([...newRefinements[verticalFilters[key].category], option])] // Using Set to remove duplicates
            : [option];
        } else {
          newRefinements[verticalFilters[key].category] = newRefinements[verticalFilters[key].category]
            ? newRefinements[verticalFilters[key].category].filter(facetValue => facetValue !== option)
            : [];
        }
      });
    });

    const params = cleanupSearchParams(searchParams);
    const appliedRefinements = Object.keys(newRefinements).some(key => newRefinements[key].length > 0);

    // Condition to avoid re-updating search params after clicking 'clear all' on second level results
    if (!params.get('tab') || appliedRefinements) {
      updateSearchParams({ ...params, ...newRefinements });
    }
  };

  useEffect(() => {
    if (pageState.activePageType === PRE_QUERY) {
      clearRefinements(webview ? ['tab'] : []);
      actions.clearAllFilters();
    }
  }, [pageState]);

  // Listening to Redux vertical filters store changes and applying filters accordingly
  useEffect(() => {
    applyFilters();
  }, [verticalFilters]);

  // This feels like a BAD thing, and I don't have a great idea to fix it,
  // but basically the Algolia search helper merges the derived queries into
  // an array with the main search query in the order they were registered.
  // Then the results events are emitted synchronously, also in the order the
  // queries were registered.  So, order matters because we do some minor clean up
  // [ setIsSearching(false) ], in the programSearcher.on('result') callback
  // since this is the last searcher registered.
  const programSearcher = searchHelper.derive((state) => (state.setQueryParameter('hitsPerPage', ALL_TAB_PROGRAM_CARDS_LENGTH).addFacetRefinement('product', PRODUCT_TYPES.PROGRAM)));

  // Error callbacks
  searchHelper.on('error', () => actions.setIsSearching(false));

  // Result Callbacks
  //
  // These callbacks will trigger on search(), but not on searchOnce().
  // In practice, this means they will run on all searches except
  // when the learning type is set to either 'Courses' or 'Programs'.
  // They use the mergeResults method because they all run and get
  // different results for different types. So together, the values
  // that they set in the search store equate to a single search result set.
  searchHelper.on('result', ({ results }) => {
    // Generic actions that need to be shared by the derived searchers
    // must happen in this callback.

    const parsedResults = parseFacetsFromResults(results);
    actions.setFacets(parsedResults);

    // Updating vertical filters store with facets values
    const currentRefinements = getRefinements(learningTypeExperimentIsActive);
    const parsedFilters = parseVerticalFiltersFacets(
      verticalFilters,
      currentRefinements,
      filterMessages,
      intl,
      parsedResults,
    );
    actions.updateFilters(parsedFilters);
  });

  execEdSearcher.on('result', ({ results }) => {
    const products = parseRawSearchResults(results);
    actions.mergeResults({ [RESULT_TYPE_EXECUTIVE_EDUCATION]: products });
  });

  bootcampSearcher.on('result', ({ results }) => {
    const products = parseRawSearchResults(results);
    actions.mergeResults({ [RESULT_TYPE_BOOT_CAMP]: products });
  });

  courseSearcher.on('result', ({ results }) => {
    const products = parseRawSearchResults(results);
    actions.mergeResults({ [RESULT_TYPE_COURSE]: products });
  });

  twoUDegreeSearcher.on('result', ({ results }) => {
    const degrees = parseRawSearchResults(results);
    actions.mergeResults({ [RESULT_TYPE_DEGREE_PROGRAM]: degrees });
  });

  programSearcher.on('result', ({ results }) => {
    const products = parseRawSearchResults(results);
    actions.mergeResults({ [RESULT_TYPE_PROGRAM]: products });
    // Cleanup based on this being the last registered searcher.
    // See comment above when the programSearcher variable is derived from
    // the searchHelper.
    actions.setIsSearching(false);
  });

  const maybeResultTypeSearch = (algoliaHelper, resultType, pageNumber = 1) => {
    // if (combinedSearchResultsExperimentOn()) { // experiment WS-4467
    //   return algoliaHelper
    //     .searchOnce({ page: pageNumber - 1, hitsPerPage: CARDS_PER_PAGE })
    //     .then(({ error, content }) => {
    //       if (error) {
    //         return { error };
    //       }

    //       return {
    //         facets: parseFacetsFromResults(content),
    //         results: { all: parseRawSearchResults(content) },
    //       };
    //     });
    // }

    const discoveryProductTypes = [
      RESULT_TYPE_COURSE,
      RESULT_TYPE_PROGRAM,
      RESULT_TYPE_EXECUTIVE_EDUCATION,
      RESULT_TYPE_BOOT_CAMP,
      RESULT_TYPE_DEGREE_PROGRAM,
    ];

    if (!discoveryProductTypes.includes(resultType)) {
      return false;
    }
    // Use searchOnce() when the activeResultType is one of
    // courses or programs.  This avoids firing the derived helpers.
    // This minimizes the number of requests and data going to
    // and from Algolia.
    const productTypeMap = {
      [RESULT_TYPE_COURSE]: 'Course',
      [RESULT_TYPE_PROGRAM]: 'Program',
      [RESULT_TYPE_EXECUTIVE_EDUCATION]: 'Executive Education',
      [RESULT_TYPE_DEGREE_PROGRAM]: '2U Degree',
      [RESULT_TYPE_BOOT_CAMP]: 'Boot Camp',
    };

    // pageNumber off by one due to Algolia pagination starts with 0
    return algoliaHelper
      .addFacetRefinement('product', productTypeMap[resultType])
      .searchOnce({ page: pageNumber - 1, hitsPerPage: CARDS_PER_PAGE })
      .then(({ error, content }) => {
        if (error) {
          return { error };
        }

        return {
          facets: parseFacetsFromResults(content),
          results: { [resultType]: parseRawSearchResults(content) },
        };
      });
  };

  // Triggers search when the url params change.
  // This should be the only place where search is triggered,
  // which means that any component that wants to cause the
  // search to happen should do so via navigation.
  useEffect(() => {
    // First things first, close the autocomplete
    // box and reset the results.

    const triggerSearch = async () => {
      resetAutocompleteResults();
      // value never altered from false default, no need to reset state
      // setPreQueryOpen(false);
      // setAutocompleteOpen(false);

      const currentPageState = {
        query: getQuery(),
        refinements: getRefinements(learningTypeExperimentIsActive),
        resultType: getResultTypeFromUrl(searchParams),
        pageType: getPageTypeFromUrl(searchParams),
      };

      // no query or refinements, use default results
      const searchIsEmpty = !getQuery()
        && JSON.stringify(currentPageState.refinements) === '{}'
        && !LEARNING_OPTION_PRODUCT_TYPES.includes(currentPageState.resultType);

      if (searchIsEmpty) {
        setResultsToDefault();
        managePageState(currentPageState);
        return;
      }

      // Reset the state of the search helper if there is any.
      // We can rebuild each search state using values in the url instead.
      searchHelper.clearRefinements();
      searchHelper.setQuery(currentPageState.query);
      Object.entries(currentPageState.refinements).forEach(([facetName, facetValues]) => {
        facetValues.forEach((facetValue) => {
          searchHelper.addDisjunctiveFacetRefinement(facetName, facetValue);
        });
      });

      actions.setIsSearching(true);
      const page = searchParams.get('page');
      const normalizedPage = page ? parseInt(page, 10) || 1 : 1;
      const didResultTypeSearch = await maybeResultTypeSearch(
        searchHelper,
        currentPageState.resultType,
        normalizedPage,
      );

      if (didResultTypeSearch && didResultTypeSearch.error) {
        actions.setIsSearching(false);
      }

      if (didResultTypeSearch && didResultTypeSearch.results) {
        actions.setFacets(didResultTypeSearch.facets || {});
        actions.replaceResults(didResultTypeSearch.results);
        actions.setIsSearching(false);

        // Updating vertical filters store with facets values
        const currentRefinements = getRefinements(learningTypeExperimentIsActive);
        const parsedFilters = parseVerticalFiltersFacets(
          verticalFilters,
          currentRefinements,
          filterMessages,
          intl,
          didResultTypeSearch.facets,
        );
        actions.updateFilters(parsedFilters);
      }

      if (!didResultTypeSearch) {
        // Calling the main search method will fire cause the
        // `results` event to be fired for the main searchHelper and
        // the derived course and program helpers, which is where they
        // will update state in the store.
        searchHelper.search();
      }

      // Manage the component state based on the current url parameters.
      managePageState(currentPageState);
    };

    triggerSearch();
  }, [searchParams, geotargetingEnabled, indexName]);

  useEffect(() => {
    actions.setMarketingPromotions(marketingPromotions);
    requestIsEnterpriseUser();
  }, []);

  const redirectToPLP = useMemo(() => ({ redirectToPLP: true }), []);
  // no need to haver this as memo
  const searchPageContext = useMemo(() => ({
    pageType: pageState.activePageType,
    resultType: pageState.activeResultType,
  }), [pageState.activePageType, pageState.activeResultType]);

  // Avoiding hydration mismatch errors and displaying search page skeleton until everything's ready
  if (!runtimeRenderable || optimizelyIsLoading) {
    return (
      <div className={classNames('new-search-page', 'search-results')}>
        <SearchHeader
          autocompleteState={autocompleteState}
          learningTypeExperimentIsActive={learningTypeExperimentIsActive}
        />
        <div>
          <SearchBreadcrumb
            breadcrumbLevel={breadCrumbParams}
            className="mt-4"
          />
          <SearchResultsSkeleton />
        </div>
      </div>
    );
  }

  return (
    <SearchPageContext.Provider value={searchPageContext}>
      <PaidLandingPageContext.Provider value={redirectToPLP}>
        <div className={
          classNames(
            'new-search-page',
            'search-results',
            { 'bg-light-200': showVerticalFilters },
          )
        }
        >
          {!showVerticalFilters && (
            <>
              <SearchHeader
                autocompleteState={autocompleteState}
              />
              <SearchBreadcrumb
                breadcrumbLevel={breadCrumbParams}
                className="mt-4"
                isLoading={false}
              />
            </>
          )}
          {/* {(!isMounted
            && (!showVerticalFilters || pageState.activePageType === PRE_QUERY)) && <SearchResultsSkeleton />} */}
          {pageState.activePageType === PRE_QUERY
            && (
              <PreQueryPage
                promotedProducts={pageContext.promotedProducts}
                promotedExecEd={pageContext.promotedExecEd}
                promotedBootcamp={pageContext.promotedBootcamp}
                promotedMasters={pageContext.promotedMasters}
                promotedBachelors={pageContext.promotedBachelors}
                courseLocationRestrictionMap={courseLocationRestrictionMap}
                programLocationRestrictionMap={programLocationRestrictionMap}
                userEnrollments={userEnrollments}
                webview={webview}
              />
            )}
          <Container size="xl" className={showVerticalFilters ? 'd-flex flex-row side-filters-layout-container' : ''}>
            {showVerticalFilters && (
              <VerticalFilters learningTypeExperimentIsActive={learningTypeExperimentIsActive} />
            )}
            {pageState.activePageType !== PRE_QUERY && runtimeRenderable && (
              <>
                <SearchResults
                  lastSearchedQuery={pageState.lastSearchedQuery}
                  promotedPrograms={showVerticalFilters ? promotedPrograms.slice(0, 3) : promotedPrograms}
                  promotedCourses={showVerticalFilters ? promotedCourses.slice(0, 3) : promotedCourses}
                  indexName={indexName}
                  refinements={pageState.refinements}
                  webView={webview}
                  userEnrollments={userEnrollments}
                  showQueryResultCount
                  learningTypeExperimentIsActive={learningTypeExperimentIsActive}
                />
                {showUserZoomSurvey ? (
                  <UserZoomSurvey
                    id={SEARCH_RESULTS_SURVEY.id}
                    sid={SEARCH_RESULTS_SURVEY.sid}
                  />
                ) : null}
              </>
            )}
          </Container>
        </div>
        <div className={
          classNames(
            'search-page-modules',
            { 'bg-light-200': showVerticalFilters },
          )
        }
        >
          {modules && modules.map(module => (
            <ContentfulModule
              key={module.id}
              module={{ ...module, className: 'search-page-module' }}
              version={textModuleVersion}
            />
          ))}
        </div>
        <SEOPage
          meta={({
            title: intl.formatMessage(messages['prospectus.search.page.new.title']),
            description: intl.formatMessage(messages['prospectus.search.page.new.description']),
            pathname,
            robots: webview ? 'noindex, nofollow' : '',
          })}
        />
      </PaidLandingPageContext.Provider>
    </SearchPageContext.Provider>
  );
}

const RestrictionDictionaryListPropTypes = PropTypes.objectOf(
  PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.string),
    states: PropTypes.arrayOf(PropTypes.string),
  }),
);

const LocationRestrictionPropTypes = PropTypes.shape({
  allowlist: RestrictionDictionaryListPropTypes,
  blocklist: RestrictionDictionaryListPropTypes,
});

SearchPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    defaultPrograms: PropTypes.arrayOf(PropTypes.shape(programFields)).isRequired,
    defaultCourses: PropTypes.arrayOf(PropTypes.shape(courseFields)).isRequired,
    promotedPrograms: PropTypes.arrayOf(PropTypes.shape(programFields)).isRequired,
    promotedCourses: PropTypes.arrayOf(PropTypes.shape(courseFields)).isRequired,
    promotedExecEd: PropTypes.arrayOf(PropTypes.shape(courseFields)).isRequired,
    promotedBootcamp: PropTypes.arrayOf(PropTypes.shape(courseFields)).isRequired,
    promotedMasters: PropTypes.arrayOf(PropTypes.shape(programFields)).isRequired,
    promotedBachelors: PropTypes.arrayOf(PropTypes.shape(programFields)).isRequired,
    promotedProducts: PropTypes.shape({
      mostPopularProducts: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape(programFields),
          PropTypes.shape(courseFields),
        ]),
      ),
      newProducts: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape(programFields),
          PropTypes.shape(courseFields),
        ]),
      ),
      trendingProducts: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape(programFields),
          PropTypes.shape(courseFields),
        ]),
      ),
    }).isRequired,
    webview: PropTypes.bool,
    initialRefinementCounts: PropTypes.shape({}).isRequired,
    initialTotalCourses: PropTypes.number.isRequired,
    initialTotalPrograms: PropTypes.number.isRequired,
    courseLocationRestrictionMap: LocationRestrictionPropTypes.isRequired,
    programLocationRestrictionMap: LocationRestrictionPropTypes.isRequired,
    marketingPromotions: marketingPromotionPropTypes.isRequired,
    modules: PropTypes.arrayOf(ContentfulModulesPropType).isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  store: PropTypes.shape({
    isSearching: PropTypes.bool,
    facets: PropTypes.shape({}).isRequired,
  }),
  actions: PropTypes.shape({
    setIsSearching: PropTypes.func.isRequired,
    setFacets: PropTypes.func.isRequired,
    mergeResults: PropTypes.func.isRequired,
    replaceResults: PropTypes.func.isRequired,
    resetSearch: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    setMarketingPromotions: PropTypes.func.isRequired,
    clearAllFilters: PropTypes.func.isRequired,
  }).isRequired,
  userEnrollments: PropTypes.arrayOf(PropTypes.shape({
    courseId: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
  })),
  requestIsEnterpriseUser: PropTypes.func,
};

SearchPage.defaultProps = {
  store: {
    isSearching: false,
  },
  userEnrollments: [],
  requestIsEnterpriseUser: () => { },
};

const mapStateToProps = (state) => ({
  store: {
    isSearching: state.search.isSearching,
    facets: state.search.facets,
  },
  isEnterpriseUser: state.enterpriseUser,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setIsSearching(value) {
      return dispatch(searchActions.setIsSearching(value));
    },
    setFacets(value) {
      return dispatch(searchActions.setFacets(value));
    },
    mergeResults(value) {
      return dispatch(searchActions.mergeResults(value));
    },
    replaceResults(value) {
      return dispatch(searchActions.replaceResults(value));
    },
    resetSearch(defaults) {
      return dispatch(searchActions.resetSearch(defaults));
    },
    updateFilters(value) {
      return dispatch(updateFilters(value));
    },
    setMarketingPromotions(value) {
      return dispatch(searchActions.setMarketingPromotions(value));
    },
    clearAllFilters() {
      return dispatch(clearAllFilters());
    },
  },
  requestIsEnterpriseUser() {
    return dispatch(enterpriseUser());
  },
});

const ConnectedSearchPage = connect(mapStateToProps, mapDispatchToProps)(SearchPage);

export default track(() => ({
  component: 'search-page',
}))(withUser(injectIntl(ConnectedSearchPage)));
